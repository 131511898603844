import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Croppie } from 'croppie';
import 'croppie/croppie.css';

import {
  Badge,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
} from 'reactstrap';

import Loading from '../Loading';

const squareSize = 800;
const ctrl = {
  height: squareSize,
  width: squareSize,
  type: 'square',
};

const e = document.documentElement;
const g = document.getElementsByTagName('body')[0];
const parentW = window.innerWidth || e.clientWidth || g.clientWidth;
const parentH = window.innerHeight || e.clientHeight || g.clientHeight;
const target = (parentH > parentW) ? (parentW - 50) : (parentH - 250);

const w = target < ctrl.width ? target : ctrl.width;
const h = target < ctrl.width ? Math.round(target * ctrl.height / ctrl.width) : ctrl.height;

class Cropper extends Component {
  constructor(props) {
    super(props);
    this.croppieContainer = React.createRef();
    this.state = {
      loading: false,
    };
  }

  componentDidMount() {
    const { src } = this.props;
    const el = this.croppieContainer.current;
    const options = {
      viewport: { width: w, height: h, type: ctrl.type },
      boundary: { width: w, height: h },
      enableOrientation: true,
      enableExif: true,
      showZoomer: false,
    };
    const croppie = new Croppie(el, options);
    croppie.bind({ url: src }).then(() => this.setState({ loading: false }));
  }

  render() {
    const { loading } = this.state;
    if (loading) {
      return <Loading />;
    }

    return (<div id="croppieContainer" ref={this.croppieContainer} />);
  }
}
Cropper.propTypes = {
  src: PropTypes.string.isRequired,
};

export default Cropper;
