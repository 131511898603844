import { Component } from 'react';
import FormValidator from './FormValidator';

class FormCapable extends Component {
  constructor(props, rules) {
    super(props);
    this.validator = new FormValidator(rules);

    // Populate rule state with empty object
    const formData = {};
    rules.forEach((rule) => {
      const { field } = rule;
      formData[field] = '';
    });

    // We save the rules to later reset the form
    this.rules = rules;

    this.state = {
      formData,
      submitting: false,
      dirty: false,
    };
  }

  getValidationVariable() {
    const { dirty, formData } = this.state;
    // if the form has been submitted at least once
    // then check validity every time we render
    // otherwise just use what's in state
    let validation = this.validator.valid();
    if (dirty === true) {
      validation = this.validator.validate(formData);
    }
    return validation;
  }

  handleInputChange(event) {
    const { formData } = this.state;
    const { target } = event;
    const { name } = target;
    let { value } = target;

    if (target.type === 'checkbox') {
      value = target.checked;
    }
    // If value is a number, typecast
    if (/^\d+$/.test(value)) {
      value = parseInt(value, 10);
    }
    // Update formData props
    // Todo this is a bit dodgy
    formData[name] = value;
    this.setState({
      formData,
      dirty: true,
    });
  }

  handleSubmit(event, callback) {
    event.preventDefault();
    const { formData, submitting } = this.state;
    if (submitting) {
      return;
    }
    const validation = this.validator.validate(formData);
    if (validation.isValid) {
      this.setState({ submitting: true });
      callback();
    } else {
      this.setState({ dirty: true });
    }
  }

  resetForm() {
    // Populate rule state with empty object
    const formData = {};
    this.rules.forEach((rule) => {
      const { field } = rule;
      formData[field] = '';
    });
    this.setState({
      formData,
      dirty: false,
    });
  }
}

export default FormCapable;
