import axios from 'axios';

import { doLogin, doLogout, addAuthorizationHeader } from '../services/AuthService';
import getConfigForEnvironment from '../config/getConfigForEnvironment';

class Api {
  constructor(config) {
    this.config = config;
    this.instance = axios.create({
      baseURL: config.api.baseUri,
      // TODO Needs investigaton
      // A timeout of ~1000 will fail upon the first request
      timeout: 30000,
      headers: { Accept: 'application/json' },
    });
    // TODO Intercept auth tokens?
    this.instance.interceptors.response.use(
      response => response,
      (error) => {
        if (error.response.status === 401) {
          window.location.replace('/#/login/session_expired');
        }
        if (error.response.status === 403) {
          // TODO something with unauthorized
        }
        if (error.response.status === 404) {
          // TODO ?
          // window.location.replace('/#/404');
        }
        return Promise.reject(error);
      },
    );
  }

  apiCreatePostComment(postSlug, pribloUserId, commentText) {
    return this.instance.post(`post/${postSlug}/comment/create`, { pribloUserId, commentText }, addAuthorizationHeader());
  }

  apiDeletePostComment(postSlug, commentId) {
    return this.instance.delete(`post/${postSlug}/comment/${commentId}/delete`, addAuthorizationHeader());
  }

  apiCreatePostLike(postSlug) {
    return this.instance.put(`post/${postSlug}/like/create`, {}, addAuthorizationHeader());
  }

  apiDeletePostLike(postSlug) {
    return this.instance.delete(`post/${postSlug}/like/delete`, addAuthorizationHeader());
  }

  readMemories(pribloSlug) {
    return this.instance.get(`posts/in/${pribloSlug}/memories`, addAuthorizationHeader());
  }

  readPaginatedPosts(pribloSlug, page) {
    return this.instance.get(`posts/in/${pribloSlug}?page=${page}`, addAuthorizationHeader());
  }

  readPriblo(pribloSlug) {
    return this.instance.get(`priblo/${pribloSlug}/read`, addAuthorizationHeader());
  }

  readLastSelectedPriblo() {
    return this.instance.get('priblo/read/lastSelected', addAuthorizationHeader());
  }

  readPriblos() {
    return this.instance.get('priblos/read', addAuthorizationHeader());
  }

  authLogin(username, password) {
    const oauthPasswordGrantData = {
      username,
      password,
      grant_type: 'password',
      client_id: this.config.api.client[0],
      client_secret: this.config.api.client[1],
      scope: '*',
    };
    const authLogin = this.instance.post('oauth/token', oauthPasswordGrantData);
    authLogin.then((response) => {
      const { data } = response;
      const accessToken = data.access_token;
      const expiresIn = data.expires_in;
      const refreshToken = data.refresh_token;
      doLogin({
        accessToken,
        expiresIn,
        refreshToken,
      });
      // This will be caught in Login.jsx
      // We catch it to avoid uncaught errors in console
    }).catch(() => {});
    return authLogin;
  }

  authLogout() {
    const authLogout = this.instance.delete('oauth/token', addAuthorizationHeader());
    authLogout.then(() => {
      doLogout();
      // This will be caught in Login.jsx
      // We catch it to avoid uncaught errors in console
    }).catch(() => {});
    return authLogout;
  }
}

export default new Api(getConfigForEnvironment());
