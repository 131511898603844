import {
  shape,
  string,
  number,
  bool,
  arrayOf,
} from 'prop-types';

export const postCommentShape = shape({
  id: number.isRequired,
  body: string.isRequired,
  author: string.isRequired,
  updatedAt: string.isRequired,
  timeago: string.isRequired,
  canUpdate: bool.isRequired,
  canDelete: bool.isRequired,
});

export const postShape = shape({
  header: string,
  author: string.isRequired,
  type: string.isRequired,
  text: string.isRequired,
  slug: string.isRequired,
  favorited: number.isRequired,
  createdAt: string.isRequired,
  timeago: string.isRequired,
  image: string.isRequired, // This is empty if type not image
  liked: bool.isRequired,
  commentsCount: number.isRequired,
  comments: arrayOf(shape(postCommentShape)).isRequired,
  likesCount: number.isRequired,
  canCreatePostIn: bool.isRequired,
});

export const pribloShape = shape({
  slug: string.isRequired,
  createdAt: string.isRequired,
  timeago: string.isRequired,
  description: string,
  name: string.isRequired,
  privacy: string.isRequired,
  type: string,
  userId: number.isRequired,
  userName: string, // This can be null, if null no username has been chosen
  hidden: bool.isRequired,
});

export const postsShape = shape({
  postsArray: arrayOf(postShape).isRequired,
  meta: shape({
    currentPage: number.isRequired,
    lastPage: number.isRequired,
  }),
});
