import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Badge } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHeart,
  faComment,
  faImages,
  faShare,
} from '@fortawesome/pro-light-svg-icons';
import {
  faHeart as faHeartSolid,
  faComment as faCommentSolid,
} from '@fortawesome/pro-solid-svg-icons';

import LinkButton from '../components/LinkButton';
import { postShape } from '../shapes';
import { apiCreatePostLike, apiDeletePostLike, raiseApiGenericError } from '../actions';
import { LOG_LEVEL_ERROR, storeLog } from '../modules/Logger';

import './PostActions.css';

const maybePluralize = (count, noun, suffix = 's') => `${count === 0 ? 'No ' : count} ${noun}${count !== 1 ? suffix : ''}`;

class PostActions extends Component {
  state = {
    hasLiked: false,
  };

  componentWillMount() {
    const { post } = this.props;
    this.setState({ hasLiked: post.liked });
  }

  createLike() {
    const {
      post,
      actionApiCreatePostLike,
      actionRaiseApiGenericError,
    } = this.props;
    // Optimistic update
    this.setState({ hasLiked: true });
    actionApiCreatePostLike(post.slug).catch((error) => {
      actionRaiseApiGenericError('Cannot favorite post');
      storeLog(error, LOG_LEVEL_ERROR);
    });
  }

  deleteLike() {
    const {
      post,
      actionApiDeletePostLike,
      actionRaiseApiGenericError,
    } = this.props;
    // Optimistic update
    this.setState({ hasLiked: false });
    actionApiDeletePostLike(post.slug).catch((error) => {
      actionRaiseApiGenericError('Cannot favorite post');
      storeLog(error, LOG_LEVEL_ERROR);
    });
  }

  render() {
    const { post, commentsOpen, openCommentsHandler } = this.props;
    const { hasLiked } = this.state;
    const {
      commentsCount,
      likesCount,
      canCreatePostIn,
      type,
    } = post;
    const isImage = (type === 'image');
    const iconSize = '2x';

    return (
      <div className="post-actions">
        <ul className="list-unstyled list-inline">
          <li className="list-inline-item">
            {hasLiked ? (
              <LinkButton onClick={() => this.deleteLike()} color="red">
                <FontAwesomeIcon size={iconSize} icon={faHeartSolid} />
              </LinkButton>
            ) : (
              <LinkButton onClick={() => this.createLike()} color="red">
                <FontAwesomeIcon size={iconSize} icon={faHeart} />
              </LinkButton>
            )}
          </li>
          <li className="list-inline-item">
            <LinkButton onClick={() => openCommentsHandler()}>
              <FontAwesomeIcon size={iconSize} icon={commentsOpen ? faCommentSolid : faComment} />
              {commentsCount > 0 && (
                <Badge color="primary" pill>{commentsCount}</Badge>
              )}
            </LinkButton>
          </li>
          {isImage && (
            <li className="list-inline-item">
              <a href="#">
                <FontAwesomeIcon size={iconSize} icon={faImages} />
              </a>
            </li>
          )}
          {canCreatePostIn && (
            <li className="list-inline-item">
              <a href="#">
                <FontAwesomeIcon size={iconSize} icon={faShare} />
              </a>
            </li>
          )}
        </ul>
        <ul className="list-unstyled list-inline">
          <li className="list-inline-item">
            { maybePluralize(likesCount, 'like') }
          </li>
        </ul>
      </div>
    );
  }
}

PostActions.propTypes = {
  post: postShape.isRequired,
  commentsOpen: PropTypes.bool.isRequired,
  openCommentsHandler: PropTypes.func.isRequired,
  actionApiCreatePostLike: PropTypes.func.isRequired,
  actionApiDeletePostLike: PropTypes.func.isRequired,
  actionRaiseApiGenericError: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    actionApiCreatePostLike: apiCreatePostLike,
    actionApiDeletePostLike: apiDeletePostLike,
    actionRaiseApiGenericError: raiseApiGenericError,
  }, dispatch);
}

export default connect(null, mapDispatchToProps)(PostActions);
