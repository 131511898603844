// This is very hacky
// TODO use normalizr
export const postsWithMetadata = (postsArray, metadata) => (
  {
    postsArray,
    meta: {
      currentPage: metadata.current_page,
      lastPage: metadata.last_page,
    },
  }
);

export const mergeWithoutDuplicates = (firstMergable, secondMergable) => {
  const mergedState = [...firstMergable, ...secondMergable];
  // Create an array of all slugs in the position they appear, duplicate ones will appear last
  const uniqueIdentifiersPositions = mergedState.map(el => el.slug);
  // Is the current item position the same of the uniqueItendifiersPositions
  // If it's in the wrong position it means it's duplicated
  return mergedState.filter((item, pos) => uniqueIdentifiersPositions.indexOf(item.slug) === pos);
};

export const updatePostFragment = (statePosts, fragment, callback) => {
  const { slug } = fragment;
  return statePosts.map((key) => {
    let post = { ...key };
    if (post.slug === slug) {
      post = callback(post);
    }
    return post;
  });
};

export const updateLikesCount = (statePosts, fragment) => {
  const { likesCount, liked } = fragment;
  return updatePostFragment(statePosts, fragment, (post) => {
    const updatedPost = post;
    updatedPost.liked = liked;
    updatedPost.likesCount = likesCount;
    return updatedPost;
  });
};

export const updateComments = (statePosts, fragment) => {
  const { comments, commentsCount } = fragment;
  return updatePostFragment(statePosts, fragment, (post) => {
    const updatedPost = post;
    updatedPost.comments = comments;
    updatedPost.commentsCount = commentsCount;
    return updatedPost;
  });
};
