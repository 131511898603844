import React from 'react';
import {
  HashRouter,
  Route,
  Switch,
} from 'react-router-dom';

import Login from './views/Login';
import FourOFour from './views/FourOFour';
import Timeline from './views/Timeline';
import CreatePost from './views/CreatePost';
import Memories from './views/Memories';

const Routes = () => (
  <HashRouter>
    <Switch>
      <Route exact path="/timeline" component={Timeline} />
      <Route exact path="/memories" component={Memories} />
      <Route exact path="/post/create" component={CreatePost} />
      <Route exact path="/login/:message" component={Login} />
      <Route exact path="/" component={Login} />
      <Route component={FourOFour} />
    </Switch>
  </HashRouter>
);

export default Routes;
