import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Container,
} from 'reactstrap';
import { Redirect } from 'react-router-dom';

// Custom components
import NavbarTop from '../../components/NavbarTop';
import NavbarBottom from '../../components/NavbarBottom';
import {
  apiReadLastSelectedPriblo,
  raiseApiGenericError,
} from '../../actions';
import ApiError from '../../components/ApiError';
import { storeLog, LOG_LEVEL_ERROR } from '../../modules/Logger';
import { isLoggedIn } from '../../services/AuthService';

import './AppLayout.css';
import { pribloShape } from '../../shapes';
import Loading from '../../components/Loading';

export default function AppLayout(WrappedComponent) {
  class AppLayoutComponent extends Component {
    state = {
      pribloLoading: true,
    };

    componentDidMount() {
      const { actionApiReadLastSelectedPriblo, actionRaiseApiGenericError } = this.props;

      actionApiReadLastSelectedPriblo().then(() => {
        this.setState({ pribloLoading: false });
      }).catch((error) => {
        actionRaiseApiGenericError('Cannot load Priblo details');
        storeLog(error, LOG_LEVEL_ERROR);
      });
    }

    render() {
      const { priblo, apiGenericErrorString } = this.props;
      const { pribloLoading } = this.state;
      if (pribloLoading === true) {
        return (
          <Loading />
        );
      }
      if (!isLoggedIn()) {
        return (<Redirect to="/login/session_expired" />);
      }

      return (
        <div>
          <Container>
            { apiGenericErrorString
              && <ApiError errorString={apiGenericErrorString} />
            }
            <NavbarTop priblo={priblo} />
            {priblo && (
              <WrappedComponent priblo={priblo} />
            )}
            <NavbarBottom priblo={priblo} />
          </Container>
        </div>
      );
    }
  }

  AppLayoutComponent.propTypes = {
    priblo: pribloShape,
    apiGenericErrorString: PropTypes.string,
    actionApiReadLastSelectedPriblo: PropTypes.func.isRequired,
    actionRaiseApiGenericError: PropTypes.func.isRequired,
  };

  AppLayoutComponent.defaultProps = {
    priblo: null,
    apiGenericErrorString: null,
  };

  function mapStateToProps({ priblo, apiGenericErrorString }) {
    return { priblo, apiGenericErrorString };
  }

  function mapDispatchToProps(dispatch) {
    return bindActionCreators({
      actionApiReadLastSelectedPriblo: apiReadLastSelectedPriblo,
      actionRaiseApiGenericError: raiseApiGenericError,
    }, dispatch);
  }

  return connect(mapStateToProps, mapDispatchToProps)(AppLayoutComponent);
}
