import React from 'react';
import PropTypes from 'prop-types';
import ReactLoading from 'react-loading';
import styled from 'styled-components';

const CustomReactLoading = ({ className }) => (
  <ReactLoading
    type="spin"
    color="#3097d1"
    height="20%"
    width="20%"
    className={className}
  />
);
CustomReactLoading.propTypes = {
  className: PropTypes.string.isRequired,
};

const Container = styled.div`
  width: 100%;
  text-align: center;
`;

const StyledCustomReactLoading = styled(CustomReactLoading)`
  display: inline-block;
`;

const Loading = () => (
  <Container>
    <StyledCustomReactLoading />
  </Container>
);

export default Loading;
