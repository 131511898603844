import Api from '../modules/Api';

export const API_READ_POSTS_INITIAL = 'API_READ_POSTS_INITIAL';
export const API_READ_PAGINATED_POSTS = 'API_READ_PAGINATED_POSTS';
export const API_READ_PRIBLO = 'API_READ_PRIBLO';
export const API_READ_LAST_SELECTED_PRIBLO = 'API_READ_LAST_SELECTED_PRIBLO';
export const API_READ_MEMORIES = 'API_READ_MEMORIES';

export const API_CREATE_POST_COMMENT = 'API_CREATE_POST_COMMENT';
export const API_DELETE_POST_COMMENT = 'API_DELETE_POST_COMMENT';

export const API_CREATE_POST_LIKE = 'API_CREATE_POST_LIKE';
export const API_DELETE_POST_LIKE = 'API_DELETE_POST_LIK';

export const ERROR_API_GENERIC = 'ERROR_API_GENERIC';

export function apiCreatePostComment(postSlug, pribloUserId, commentText) {
  const request = Api.apiCreatePostComment(postSlug, pribloUserId, commentText);

  return {
    type: API_CREATE_POST_COMMENT,
    payload: request,
  };
}

export function apiDeletePostComment(postSlug, commentId) {
  const request = Api.apiDeletePostComment(postSlug, commentId);

  return {
    type: API_DELETE_POST_COMMENT,
    payload: request,
  };
}

export function apiCreatePostLike(postSlug) {
  const request = Api.apiCreatePostLike(postSlug);

  return {
    type: API_CREATE_POST_LIKE,
    payload: request,
  };
}

export function apiDeletePostLike(postSlug) {
  const request = Api.apiDeletePostLike(postSlug);

  return {
    type: API_DELETE_POST_LIKE,
    payload: request,
  };
}

export function apiReadFirstPageOfPosts(pribloSlug) {
  const request = Api.readPaginatedPosts(pribloSlug, 1);

  return {
    type: API_READ_POSTS_INITIAL,
    payload: request,
  };
}

export function apiReadPaginatedPosts(pribloSlug, page) {
  const request = Api.readPaginatedPosts(pribloSlug, page);

  return {
    type: API_READ_PAGINATED_POSTS,
    payload: request,
  };
}

export function apiReadMemories(pribloSlug) {
  const request = Api.readMemories(pribloSlug);

  return {
    type: API_READ_MEMORIES,
    payload: request,
  };
}

// This function should only be called by the switcher
export function apiReadPriblo(pribloSlug) {
  const request = Api.readPriblo(pribloSlug);

  return {
    type: API_READ_PRIBLO,
    payload: request,
  };
}

export function apiReadLastSelectedPriblo() {
  const request = Api.readLastSelectedPriblo();

  return {
    type: API_READ_LAST_SELECTED_PRIBLO,
    payload: request,
  };
}

export function raiseApiGenericError(apiGenericErrorString) {
  return {
    type: ERROR_API_GENERIC,
    apiGenericErrorString,
  };
}
