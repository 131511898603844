import { saveObjectByKey, loadObjectByKey, deleteObjectByKey } from '../modules/LocalStorage';

const loadTokenIfNotExpired = () => {
  const authObject = loadObjectByKey('auth');
  if (authObject === undefined) {
    return undefined;
  }
  const { expiresIn, accessToken } = authObject;
  const timestamp = Math.floor(Date.now() / 1000);
  if ((timestamp - expiresIn) <= 0) {
    return undefined;
  }
  return accessToken;
};

export const isLoggedIn = () => {
  const token = loadTokenIfNotExpired();
  if (token === undefined) {
    return false;
  }
  return true;
};

export const doLogin = authObject => saveObjectByKey('auth', authObject);

export const doLogout = () => deleteObjectByKey('auth');

export const addAuthorizationHeader = () => ({ headers: { Authorization: `Bearer ${loadTokenIfNotExpired()}` } });
