import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
} from 'reactstrap';
import SwipeableViews from 'react-swipeable-views';
import TimeAgo from 'react-timeago';

import AppLayout from './layouts/AppLayout';
import Loading from '../components/Loading';
import Post from '../components/Post';
import CallToAction from '../components/CallToAction';

import { apiReadMemories, raiseApiGenericError } from '../actions';
import { storeLog, LOG_LEVEL_ERROR } from '../modules/Logger';
import { postsShape, pribloShape } from '../shapes';
import ShouldUpdateWhenPribloUpdates from '../modules/ShouldUpdateWhenPribloUpdates';

class MemoriesView extends ShouldUpdateWhenPribloUpdates {
  state = {
    loading: true,
    index: 0,
  };

  componentWillMount() {
    this.readMemories();
  }

  componentDidUpdate(prevProps) {
    if (this.shouldTrigger(prevProps)) {
      this.readMemories();
    }
  }

  readMemories() {
    const { priblo, actionApiReadMemories, actionRaiseApiGenericError } = this.props;
    this.setState({ loading: true });

    if (this.canDisplayMemories()) {
      actionApiReadMemories(priblo.slug).then(() => {
        this.setState({ loading: false, index: 0 });
      }).catch((error) => {
        actionRaiseApiGenericError('Cannot load memories');
        storeLog(error, LOG_LEVEL_ERROR);
      });
    }
  }

  canDisplayMemories() {
    const { priblo } = this.props;
    return (priblo.type === 'baby');
  }

  render() {
    const { loading, index } = this.state;
    const { priblo, posts } = this.props;

    if (!this.canDisplayMemories()) {
      return (
        <CallToAction
          header="No memories available"
          lead="Memories are only available in baby priblos."
          info={false}
          button="Learn more!"
          clickHandler={() => {}}
        />
      );
    }

    if (loading) {
      return (
        <Loading />
      );
    }

    return (
      <Row>
        <Col xs="12">
          <SwipeableViews
            resistance
            enableMouseEvents
            ignoreNativeScroll
            index={index}
            onChangeIndex={newIndex => this.setState({ index: newIndex })}
          >
            { posts.postsArray.map(post => (
              <React.Fragment>
                <h2>
                  <TimeAgo date={post.timeago} />
                </h2>
                <Post key={post.slug} post={post} priblo={priblo} />
              </React.Fragment>
            ))}
            <CallToAction
              header="More?"
              lead="You have seen all the memories in this queue."
              info="Each queue is unique and relevant!"
              button="Start a new queue!"
              clickHandler={() => this.readMemories()}
            />
          </SwipeableViews>
        </Col>
      </Row>
    );
  }
}

MemoriesView.propTypes = {
  priblo: pribloShape.isRequired,
  posts: postsShape,
  actionApiReadMemories: PropTypes.func.isRequired,
  actionRaiseApiGenericError: PropTypes.func.isRequired,
};

MemoriesView.defaultProps = {
  posts: {},
};

function mapStateToProps({ posts }) {
  return { posts };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    actionApiReadMemories: apiReadMemories,
    actionRaiseApiGenericError: raiseApiGenericError,
  }, dispatch);
}

export default AppLayout(connect(mapStateToProps, mapDispatchToProps)(MemoriesView));
