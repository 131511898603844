import React from 'react';
import PropTypes from 'prop-types';
import {
  Jumbotron,
  Button,
} from 'reactstrap';

const CallToAction = ({
  header,
  lead,
  info,
  button,
  clickHandler,
}) => (
  <Jumbotron style={{ fontSize: '1.8rem' }}>
    <h1 className="display-3">
      {header}
    </h1>
    <p className="lead">
      {lead}
    </p>
    <hr className="my-2" />
    {info && (
      <p>
        {info}
      </p>
    )}
    <p className="lead">
      <Button color="primary" onClick={clickHandler}>
        {button}
      </Button>
    </p>
  </Jumbotron>
);

CallToAction.propTypes = {
  header: PropTypes.string.isRequired,
  lead: PropTypes.string.isRequired,
  info: PropTypes.string,
  button: PropTypes.string.isRequired,
  clickHandler: PropTypes.func.isRequired,
};

CallToAction.defaultProps = {
  info: false,
};

export default CallToAction;
