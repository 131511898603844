import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Input,
  Alert,
} from 'reactstrap';

import Loading from '../components/Loading';
import ApiError from '../components/ApiError';

import Api from '../modules/Api';
import FormCapable from '../modules/FormCapable';

import './Login.css';
import logo from '../images/logo-baby.png';

class Login extends FormCapable {
  constructor(props) {
    const validatorRules = [
      {
        field: 'email',
        method: 'isEmail',
        validWhen: true,
        message: 'Please enter a valid email.',
      },
      {
        field: 'password',
        method: 'isEmpty',
        validWhen: false,
        message: 'Please enter your password.',
      },
    ];
    super(props, validatorRules);
  }

  componentWillMount() {
    this.setState({ apiError: false, redirect: false });
  }

  attemptLogin(formData) {
    const { email, password } = formData;
    Api.authLogin(email, password).then(() => {
      this.setState({ submitting: false, redirect: true });
    }).catch(() => {
      this.setState({ submitting: false, apiError: true });
    });
  }

  render() {
    const {
      submitting,
      redirect,
      apiError,
      formData,
    } = this.state;
    const { match } = this.props;
    const validation = this.getValidationVariable();

    if (redirect) {
      return <Redirect to="/timeline" />;
    }

    return (
      <div id="app-auth">
        <div className="smack-in-the-middle">
          <Container>
            { (match.params.message) && (<Alert color="warning">Session expired, login again.</Alert>)}
            <Row className="justify-content-center">
              <Col md="4">
                <img src={logo} className="img-fluid logo" alt="Priblo logo" />
                { (apiError) && (<ApiError errorString="Invalid credentials" />)}
                <h5>Login</h5>
                <Form
                  onSubmit={event => this.handleSubmit(event, () => this.attemptLogin(formData))}
                >
                  <FormGroup>
                    <Input
                      type="email"
                      name="email"
                      placeholder="E-Mail"
                      onChange={event => this.handleInputChange(event)}
                      invalid={validation.email.isInvalid}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Input
                      type="password"
                      name="password"
                      placeholder="Password"
                      onChange={event => this.handleInputChange(event)}
                      invalid={validation.password.isInvalid}
                    />
                  </FormGroup>
                  <FormGroup className="text-center buttons">
                    {(submitting) ? (
                      <Loading />
                    ) : (
                      <Button color="primary">Login</Button>
                    )}
                  </FormGroup>
                  <footer className="text-center">
                    <a href="https://www.priblo.com/user/password/reset">Forgot your password?</a>
                    {' '}
                    -
                    {' '}
                    <a href="https://www.priblo.com/user/register">Register</a>
                  </footer>
                </Form>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
    // return <Redirect to="/" />;
  }
}

Login.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      message: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default Login;
