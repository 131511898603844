import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Form,
  InputGroupAddon,
  InputGroup,
  Input,
  Button,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEnvelope,
} from '@fortawesome/pro-light-svg-icons';

import PostComment from './PostComment';
import { postCommentShape, pribloShape } from '../shapes';
import { apiCreatePostComment, apiDeletePostComment, raiseApiGenericError } from '../actions';
import { LOG_LEVEL_ERROR, storeLog } from '../modules/Logger';

import './PostComments.css';
import FormCapable from '../modules/FormCapable';

class PostComments extends FormCapable {
  constructor(props) {
    const validatorRules = [
      {
        field: 'text',
        method: 'isEmpty',
        validWhen: false,
        message: 'Please enter your comment.',
      },
    ];
    super(props, validatorRules);
  }

  createComment(formData) {
    const {
      priblo,
      postSlug,
      actionApiCreatePostComment,
      actionRaiseApiGenericError,
    } = this.props;
    const { text } = formData;
    this.resetForm();

    actionApiCreatePostComment(postSlug, priblo.userId, text).then(() => {
      this.setState({
        submitting: false,
      });
    }).catch((error) => {
      actionRaiseApiGenericError('Cannot reach API');
      storeLog(error, LOG_LEVEL_ERROR);
      this.setState({
        submitting: false,
      });
    });
  }

  render() {
    const { comments, postSlug } = this.props;
    const { submitting, formData } = this.state;
    const validation = this.getValidationVariable();

    return (
      <div className="priblo_comments">
        <h5>
          Comments
        </h5>
        { comments.map(comment => (
          <PostComment
            key={comment.id}
            comment={comment}
            postSlug={postSlug}
          />
        ))}
        <Form onSubmit={event => this.handleSubmit(event, () => this.createComment(formData))}>
          <InputGroup>
            <Input
              type="text"
              name="text"
              placeholder="Add a comment..."
              onChange={event => this.handleInputChange(event)}
              invalid={validation.text.isInvalid}
              value={formData.text}
              disabled={submitting}
            />
            <InputGroupAddon addonType="append">
              <Button color="link">
                <FontAwesomeIcon icon={faEnvelope} size="2x" spin={submitting} />
              </Button>
            </InputGroupAddon>
          </InputGroup>
        </Form>
      </div>

    );
  }
}

PostComments.propTypes = {
  postSlug: PropTypes.string.isRequired,
  comments: PropTypes.arrayOf(PropTypes.shape(postCommentShape)).isRequired,
  priblo: pribloShape.isRequired,
  actionApiCreatePostComment: PropTypes.func.isRequired,
  actionApiDeletePostComment: PropTypes.func.isRequired,
  actionRaiseApiGenericError: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    actionApiCreatePostComment: apiCreatePostComment,
    actionApiDeletePostComment: apiDeletePostComment,
    actionRaiseApiGenericError: raiseApiGenericError,
  }, dispatch);
}

export default connect(null, mapDispatchToProps)(PostComments);
