import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Card,
  CardBody,
} from 'reactstrap';
import TimeAgo from 'react-timeago';
import { confirmAlert } from 'react-confirm-alert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTrashAlt,
} from '@fortawesome/pro-light-svg-icons';

import { postCommentShape } from '../shapes';
import { apiDeletePostComment, raiseApiGenericError } from '../actions';
import { LOG_LEVEL_ERROR, storeLog } from '../modules/Logger';

import './PostComment.css';
import LinkButton from '../components/LinkButton';

class PostComment extends Component {
  state = {
    deleting: false,
  };

  deleteComment(commentId) {
    const {
      postSlug,
      actionApiDeletePostComment,
      actionRaiseApiGenericError,
    } = this.props;
    confirmAlert({
      title: 'Are you sure you want to delete your comment?',
      message: 'This action cannot be undone.',
      buttons: [
        {
          label: 'Yes, delete',
          onClick: () => {
            this.setState({ deleting: true });
            actionApiDeletePostComment(postSlug, commentId).then(() => {
            }).catch((error) => {
              actionRaiseApiGenericError('Cannot delete comment');
              storeLog(error, LOG_LEVEL_ERROR);
            });
          },
        },
        {
          label: 'No',
          onClick: () => {},
        },
      ],
    });
  }

  render() {
    const { comment } = this.props;
    const { deleting } = this.state;

    return (
      <Card>
        <CardBody className={deleting ? 'disabled' : ''}>
          <Row>
            <Col xs="10">
              <p>
                <strong>{comment.author}</strong>
                {' - '}
                {comment.body}
              </p>
              <p className="timestamp">
                <TimeAgo date={comment.timeago} />
              </p>
            </Col>
            <Col xs="2" className="text-right">
              {comment.canUpdate && (
                <LinkButton onClick={() => {
                  if (!deleting) {
                    this.deleteComment(comment.id);
                  }
                }}
                >
                  <FontAwesomeIcon icon={faTrashAlt} spin={deleting} />
                </LinkButton>
              )}
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  }
}

PostComment.propTypes = {
  postSlug: PropTypes.string.isRequired,
  comment: postCommentShape.isRequired,
  actionApiDeletePostComment: PropTypes.func.isRequired,
  actionRaiseApiGenericError: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    actionApiDeletePostComment: apiDeletePostComment,
    actionRaiseApiGenericError: raiseApiGenericError,
  }, dispatch);
}

export default connect(null, mapDispatchToProps)(PostComment);
