import React from 'react';
import PropTypes from 'prop-types';

import './LinkButton.css';

const LinkButton = ({
  color,
  onClick,
  children,
}) => (
  <button
    type="button"
    className="linkButton"
    onClick={onClick}
    style={{ color }}
  >
    {children}
  </button>
);

LinkButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  color: PropTypes.string,
  children: PropTypes.node.isRequired,
};

LinkButton.defaultProps = {
  color: '#007bff',
};

export default LinkButton;
