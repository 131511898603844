const privacyToColor = (privacy) => {
  let color = '';
  switch (privacy) {
    case 'private':
      color = 'success';
      break;
    case 'shared':
      color = 'warning';
      break;
    case 'public':
      color = 'danger';
      break;
    default:
      color = 'danger';
      break;
  }
  return color;
};
export default privacyToColor;
