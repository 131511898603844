import React from 'react';
import { Alert } from 'reactstrap';
import PropTypes from 'prop-types';

const ApiError = ({ errorString }) => (
  <Alert color="danger">
    { errorString }
  </Alert>
);

ApiError.propTypes = {
  errorString: PropTypes.string.isRequired,
};

export default ApiError;
