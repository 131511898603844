import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  FormGroup,
  Button,
} from 'reactstrap';

import Cropper from './Cropper';

import './index.css';

class Uploader extends Component {
  state = {
    src: null,
  };

  constructor(props) {
    super(props);
    this.cropper = React.createRef();
  }

  onChange(event) {
    event.preventDefault();
    const { files } = event.target;
    const reader = new FileReader();

    reader.onload = (e) => {
      const readImage = e.target.result;
      this.setState({ src: readImage });
    };
    reader.readAsDataURL(files[0]);
  }

  cropImage() {
    const cropperRef = this.cropper.current;
    if (typeof cropperRef.getCroppedCanvas() === 'undefined') {
      return;
    }
    this.setState({
      cropResult: cropperRef.getCroppedCanvas().toDataURL(),
    });
  }

  render() {
    const {
      src,
    } = this.state;
    return (
      <FormGroup>
        <div className="attachment-area">
          <label
            className="btn btn-default btn-outline-primary"
            htmlFor="image-upload-btn"
          >
            <span>Add image</span>
            <input
              type="file"
              id="image-upload-btn"
              accept="image/*"
              style={{ display: 'none' }}
              onChange={event => this.onChange(event)}
            />
          </label>
          { src && (
            <Cropper src={src} />
          )}
        </div>
      </FormGroup>
    );
  }
}
Uploader.propTypes = {
  className: PropTypes.string.isRequired,
};

export default Uploader;
