import { PureComponent } from 'react';
import { pribloShape } from '../shapes';

class ShouldUpdateWhenPribloUpdates extends PureComponent {
  /**
   * TODO prevent issues with undefined forcing updates
   * @param prevProps
   */
  shouldTrigger(prevProps) {
    const prevPriblo = prevProps.priblo;
    const { priblo } = this.props;
    return (prevPriblo.slug !== priblo.slug);
  }
}

ShouldUpdateWhenPribloUpdates.propTypes = {
  priblo: pribloShape.isRequired,
};

export default ShouldUpdateWhenPribloUpdates;
