import React from 'react';
import {
  Button,
  Navbar,
  Nav,
  NavItem,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlusOctagon,
  faUser,
  faClock,
  faRectanglePortrait,
} from '@fortawesome/pro-light-svg-icons';

import './NavbarBottom.css';

const NavbarBottom = () => (
  <Navbar color="light" light fixed="bottom" className="navbarBottom">
    <Nav className="mx-auto">
      <NavItem>
        <Link to="timeline">
          <FontAwesomeIcon icon={faRectanglePortrait} size="3x" />
        </Link>
      </NavItem>
      <NavItem>
        <Link to="post/create">
          <FontAwesomeIcon icon={faPlusOctagon} size="3x" />
        </Link>
      </NavItem>
      <NavItem>
        <Link to="memories">
          <FontAwesomeIcon icon={faClock} size="3x" />
        </Link>
      </NavItem>
      <NavItem>
        <Button color="link">
          <FontAwesomeIcon icon={faUser} size="3x" />
        </Button>
      </NavItem>
    </Nav>
  </Navbar>
);


NavbarBottom.propTypes = {
};

export default NavbarBottom;
