import React, { Component } from 'react';
import {
  Card,
} from 'reactstrap';
import LazyLoad from 'react-lazyload';
import TimeAgo from 'react-timeago';

import PostActions from '../containers/PostActions';
import RendersMarkdown from './RendersMarkdown';
import { pribloShape, postShape } from '../shapes';

import ImageLoading from '../images/img-loading-500px.png';
import './Post.css';
import PostComments from '../containers/PostComments';

class Post extends Component {
  state = {
    displayComments: true,
  };

  displayPostActions() {
    const { post } = this.props;
    const { displayComments } = this.state;
    return (
      <PostActions
        post={post}
        commentsOpen={displayComments}
        openCommentsHandler={() => this.setState({ displayComments: !displayComments })}
      />
    );
  }

  displayPostText() {
    const { post } = this.props;
    const { text } = post;
    return (
      <div className="card-body">
        <div className="card-text">
          <RendersMarkdown markdown={text} />
        </div>
      </div>
    );
  }

  render() {
    const { post, priblo } = this.props;
    const { displayComments } = this.state;
    const {
      slug,
      type,
      author,
      createdAt,
      timeago,
      image,
      comments,
      header,
    } = post;
    const isNew = false;
    const borderColor = '';
    const isImage = (type === 'image');

    const imageLoadingPlaceholder = (
      <img
        src={ImageLoading}
        style={{ width: '100%' }}
        alt="loading.."
      />
    );

    return (
      <Card style={{ borderColor }} className="priblo_post">
        {isNew && (
          <span className="badge badge-pill badge-success">
            New
          </span>
        )}
        {header && (
          <div className="card-header">
            { header }
          </div>
        )}
        {isImage ? (
          <React.Fragment>
            <LazyLoad
              height={500}
              offset={100}
              once
              placeholder={imageLoadingPlaceholder}
            >
              <img
                className="card-img-top img-fluid"
                src={image}
                alt="Please refer to post text"
              />
            </LazyLoad>
            { this.displayPostActions() }
            { this.displayPostText() }
          </React.Fragment>
        ) : (
          <React.Fragment>
            { this.displayPostText() }
            { this.displayPostActions() }
          </React.Fragment>
        )}
        <div className="blog-post-meta text-right">
          <p>
            {`${author} - `}
            {createdAt}
            {' '}
            (
            <TimeAgo date={timeago} />
            )
          </p>
        </div>
        {displayComments && (
          <PostComments
            postSlug={slug}
            comments={comments}
            priblo={priblo}
          />
        )}
      </Card>
    );
  }
}

Post.propTypes = {
  priblo: pribloShape.isRequired,
  post: postShape.isRequired,
};

export default Post;
