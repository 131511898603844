import React, { Component } from 'react';
import Menu from 'react-burger-menu/lib/menus/slide';
import { ListGroup, ListGroupItem } from 'reactstrap';
import PropTypes from 'prop-types';
import Toggle from 'react-toggle-component';
import 'react-toggle-component/styles.css';

import { pribloShape } from '../shapes';

import './Sidebar.css';

class SideBar extends Component {
  state = {
    sidebarOpen: false,
    displayHidden: false,
  };

  toggleSideBar() {
    const { sidebarOpen } = this.state;
    this.setState({ sidebarOpen: !sidebarOpen });
  }

  renderSideBarContent() {
    const { priblos, priblo, switchPribloHandler } = this.props;
    const { displayHidden } = this.state;
    const priblosWithoutCurrent = priblos.filter(obj => obj.slug !== priblo.slug);
    const priblosWithOrWithoutHidden = priblosWithoutCurrent.filter(
      obj => obj.hidden !== displayHidden,
    );

    return (
      <ListGroup flush>
        <ListGroupItem>
          <Toggle
            onToggle={(checked) => { this.setState({ displayHidden: checked }); }}
            checked={displayHidden}
            labelRight="Display hidden"
          />
        </ListGroupItem>
        { priblosWithOrWithoutHidden.map(({ name, slug }) => (
          <ListGroupItem
            key={slug}
            onClick={() => switchPribloHandler(slug)}
          >
            {name}
          </ListGroupItem>
        ))}
      </ListGroup>
    );
  }

  render() {
    const { sidebarOpen } = this.state;

    return (
      <Menu
        customBurgerIcon={false}
        customCrossIcon={false}
        isOpen={sidebarOpen}
        disableOverlayClick
        noOverlay
        width={300}
        right
      >
        {this.renderSideBarContent()}
      </Menu>
    );
  }
}

SideBar.propTypes = {
  priblos: PropTypes.arrayOf(pribloShape).isRequired,
  priblo: pribloShape.isRequired,
  switchPribloHandler: PropTypes.func.isRequired,
};

export default SideBar;
