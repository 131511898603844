import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import {
  Navbar,
  NavbarBrand,
  Nav,
} from 'reactstrap';
import { Link, Redirect } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExchange } from '@fortawesome/pro-light-svg-icons';

import SideBar from './Sidebar';
import LinkButton from './LinkButton';
import { saveObjectByKey, loadObjectByKey } from '../modules/LocalStorage';
import { apiReadPriblo, raiseApiGenericError } from '../actions';
import { LOG_LEVEL_ERROR, storeLog } from '../modules/Logger';
import Api from '../modules/Api';
import { pribloShape } from '../shapes';

import './NavbarTop.css';
import logo from '../images/logo-squared-500px.png';
import logoBaby from '../images/logo-baby-squared-500px.png';

class NavbarTop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loggingOut: false,
      switchingPiblo: false,
      priblos: null,
    };
    this.sideBarRef = React.createRef();
  }

  componentDidMount() {
    const priblosList = loadObjectByKey('priblos');
    if (priblosList === undefined) {
      Api.readPriblos().then((response) => {
        const { priblos } = response.data;
        saveObjectByKey('priblos', priblos);
        this.setState({ priblos });
      }).catch((error) => {
        const { actionRaiseApiGenericError } = this.props;
        actionRaiseApiGenericError('Cannot load Priblo details');
        storeLog(error, LOG_LEVEL_ERROR);
      });
    } else {
      this.setState({ priblos: priblosList });
    }
  }

  switchPriblo(pribloSlug) {
    const { actionApiReadPriblo, actionRaiseApiGenericError } = this.props;
    this.setState({ switchingPiblo: true });
    this.sideBarRef.current.toggleSideBar();
    actionApiReadPriblo(pribloSlug).then(() => {
      this.setState({ switchingPiblo: false });
    }).catch((error) => {
      actionRaiseApiGenericError('Cannot load Priblo details');
      storeLog(error, LOG_LEVEL_ERROR);
    });
  }

  doLogout() {
    Api.authLogout().then(
      () => { this.setState({ loggingOut: true }); },
    ).catch(
      () => { this.setState({ loggingOut: true }); },
    );
  }

  render() {
    const { priblos, loggingOut, switchingPiblo } = this.state;
    const { priblo } = this.props;
    const isBaby = (priblo.type === 'baby');
    if (loggingOut) {
      return (<Redirect to="/login/logged_out" />);
    }

    return (
      <React.Fragment>
        <Navbar className="fixed-top navbar-expand navbar-inverse navbarTop">
          <NavbarBrand>
            <Link to="timeline">
              <img
                src={(isBaby) ? logoBaby : logo}
                width="30"
                height="30"
                className="d-inline-block align-top"
                alt="Priblo logo"
              />
            </Link>
          </NavbarBrand>
          <Nav className="ml-auto" navbar>
            <LinkButton onClick={() => this.sideBarRef.current.toggleSideBar()} color="#e6f2ff">
              { priblo.name }
              {' '}
              <FontAwesomeIcon icon={faExchange} spin={switchingPiblo} />
            </LinkButton>
          </Nav>
        </Navbar>
        { priblos && (
          <SideBar
            priblos={priblos}
            priblo={priblo}
            ref={this.sideBarRef}
            switchPribloHandler={pribloSlug => this.switchPriblo(pribloSlug)}
          />
        )}
      </React.Fragment>
    );
  }
}


NavbarTop.propTypes = {
  priblo: pribloShape.isRequired,
  actionApiReadPriblo: PropTypes.func.isRequired,
  actionRaiseApiGenericError: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    actionApiReadPriblo: apiReadPriblo,
    actionRaiseApiGenericError: raiseApiGenericError,
  }, dispatch);
}

export default connect(null, mapDispatchToProps)(NavbarTop);
