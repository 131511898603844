import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {
  Row,
  Col,
  CardColumns,
} from 'reactstrap';
import Waypoint from 'react-waypoint';

import ShouldUpdateWhenPribloUpdates from '../modules/ShouldUpdateWhenPribloUpdates';
import { pribloShape, postsShape } from '../shapes';
import { apiReadFirstPageOfPosts, apiReadPaginatedPosts, raiseApiGenericError } from '../actions';
import { storeLog, LOG_LEVEL_ERROR } from '../modules/Logger';

import AppLayout from './layouts/AppLayout';
import Loading from '../components/Loading';
import Post from '../components/Post';

import './Timeline.css';

const initialState = {
  initialLoading: true,
  waypointLoading: true,
};

class TimelineView extends ShouldUpdateWhenPribloUpdates {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  componentDidMount() {
    this.loadPostsInitial();
  }

  componentDidUpdate(prevProps) {
    if (this.shouldTrigger(prevProps)) {
      this.loadPostsInitial();
    }
  }

  loadNextPostsPage() {
    // This feels a bit dodgy but it's ok for now
    // TODO refactor for added elegance
    const { posts } = this.props;
    const { currentPage, lastPage } = posts.meta;
    const nextPage = currentPage + 1;
    if (nextPage > lastPage) {
      return;
    }
    this.loadPostsInPage(nextPage);
  }

  loadPostsInPage(page) {
    const { priblo, actionApiReadPaginatedPosts, actionRaiseApiGenericError } = this.props;

    actionApiReadPaginatedPosts(priblo.slug, page).then(() => {
      this.setState({
        initialLoading: false,
      });
    }).catch((error) => {
      actionRaiseApiGenericError('Cannot load priblo posts');
      storeLog(error, LOG_LEVEL_ERROR);
    });
  }

  loadPostsInitial() {
    const { priblo, actionApiReadFirstPageOfPosts, actionRaiseApiGenericError } = this.props;

    actionApiReadFirstPageOfPosts(priblo.slug).then(() => {
      this.setState({
        initialLoading: false,
      });
    }).catch((error) => {
      actionRaiseApiGenericError('Cannot load priblo posts');
      storeLog(error, LOG_LEVEL_ERROR);
    });
  }

  render() {
    const { initialLoading, waypointLoading } = this.state;
    const { priblo, posts } = this.props;
    const { postsArray } = posts;

    if (!priblo || initialLoading === true) {
      return (
        <Loading />
      );
    }
    const { currentPage, lastPage } = posts.meta;
    const isLastPage = (currentPage === lastPage);

    return (
      <Row className="priblo_timeline_posts">
        <Col xs="12">
          <CardColumns>
            { postsArray.map(post => (
              <Post key={post.slug} post={post} priblo={priblo} />))
            }
            <Waypoint
              onEnter={() => { this.loadNextPostsPage(); }}
              threshold={0}
              topOffset="90%"
            />
          </CardColumns>
          { waypointLoading && !isLastPage && (
            <Loading />
          )}
        </Col>
      </Row>
    );
  }
}

TimelineView.propTypes = {
  priblo: pribloShape.isRequired,
  posts: postsShape,
  actionApiReadFirstPageOfPosts: PropTypes.func.isRequired,
  actionApiReadPaginatedPosts: PropTypes.func.isRequired,
  actionRaiseApiGenericError: PropTypes.func.isRequired,
};

TimelineView.defaultProps = {
  posts: {},
};
function mapStateToProps({ posts }) {
  return { posts };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    actionApiReadFirstPageOfPosts: apiReadFirstPageOfPosts,
    actionApiReadPaginatedPosts: apiReadPaginatedPosts,
    actionRaiseApiGenericError: raiseApiGenericError,
  }, dispatch);
}

export default AppLayout(connect(mapStateToProps, mapDispatchToProps)(TimelineView));
