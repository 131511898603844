import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import ReduxPromise from 'redux-promise';
import WebFont from 'webfontloader';
import * as serviceWorker from './serviceWorker';

// CSS
import 'bootstrap/dist/css/bootstrap.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import './index.css';

import { loadState, saveState } from './modules/LocalStorage';
import reducers from './reducers';
import Routes from './routes';

const persistedState = loadState();

const store = createStore(
  reducers,
  persistedState,
  applyMiddleware(ReduxPromise),
);

WebFont.load({
  google: {
    families: ['Work Sans', 'Raleway'],
  },
});

ReactDOM.render(
  // TODO use jsx extension for this file, will require to eject the create-react-app
  // eslint-disable-next-line react/jsx-filename-extension
  <Provider store={store}>
    <Routes />
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
