const { localStorage } = window;

const doSet = (key, data) => {
  try {
    localStorage.setItem(key, data);
  } catch (err) {
    // Ignore write errors.
  }
};

const doGet = (key) => {
  try {
    const data = localStorage.getItem(key);
    if (data === null) {
      return undefined;
    }
    return data;
  } catch (err) {
    return undefined;
  }
};

const doDelete = (key) => {
  try {
    localStorage.removeItem(key);
    return true;
  } catch (err) {
    return false;
  }
};

export const loadState = () => {
  const serialisedState = doGet('state');
  if (serialisedState === undefined) {
    return undefined;
  }
  return JSON.parse(serialisedState);
};

export const saveState = (state) => {
  const serialisedState = JSON.stringify(state);
  doSet('state', serialisedState);
};

export const saveObjectByKey = (key, rawObject) => {
  const serialisedObject = JSON.stringify(rawObject);
  doSet(key, serialisedObject);
};

export const loadObjectByKey = (key) => {
  const serialisedObject = doGet(key);
  if (serialisedObject === undefined) {
    return undefined;
  }
  return JSON.parse(serialisedObject);
};

export const deleteObjectByKey = (key) => {
  return doDelete(key);
};
