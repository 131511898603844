import { combineReducers } from 'redux';
import {
  API_READ_PRIBLO,
  API_READ_POSTS_INITIAL,
  API_READ_PAGINATED_POSTS,
  API_READ_MEMORIES,
  API_CREATE_POST_LIKE,
  API_DELETE_POST_LIKE,
  ERROR_API_GENERIC,
  API_CREATE_POST_COMMENT,
  API_DELETE_POST_COMMENT,
  API_READ_LAST_SELECTED_PRIBLO,
} from '../actions';
import {
  postsWithMetadata,
  mergeWithoutDuplicates,
  updatePostFragment,
  updateLikesCount,
  updateComments,
} from './Helpers';

const PostsReducer = (
  state = { postsArray: [], meta: { currentPage: 0, lastPage: 0 } },
  action,
) => {
  switch (action.type) {
    case API_READ_MEMORIES: {
      const { posts } = action.payload.data;
      const meta = { meta: { currentPage: 0, lastPage: 0 } };
      return postsWithMetadata(posts, meta);
    }
    case API_READ_POSTS_INITIAL: {
      const { posts } = action.payload.data.data;
      const { meta } = action.payload.data;
      return postsWithMetadata(posts, meta);
    }
    case API_READ_PAGINATED_POSTS: {
      const { posts } = action.payload.data.data;
      const { meta } = action.payload.data;

      // This is the first call, page 1
      // We populate the array directly from the API data
      // TODO refactor, inelegant
      if (!state.postsArray.length) {
        return postsWithMetadata(posts, meta);
      }
      return postsWithMetadata(mergeWithoutDuplicates(state.postsArray, posts), meta);
    }
    case API_CREATE_POST_LIKE:
    case API_DELETE_POST_LIKE: {
      const postFragment = action.payload.data;
      return postsWithMetadata(updateLikesCount(state.postsArray, postFragment), state.meta);
    }
    case API_CREATE_POST_COMMENT:
    case API_DELETE_POST_COMMENT: {
      const postFragment = action.payload.data;
      return postsWithMetadata(updateComments(state.postsArray, postFragment), state.meta);
    }
    default: {
      return state;
    }
  }
};

const pribloReducer = (state = null, action) => {
  switch (action.type) {
    case API_READ_LAST_SELECTED_PRIBLO:
    case API_READ_PRIBLO: {
      const priblo = action.payload.data;
      return priblo;
    }
    default:
      return state;
  }
};

const apiGenericErrorStringReducer = (state = null, action) => {
  switch (action.type) {
    case ERROR_API_GENERIC:
      return action.apiGenericErrorString;
    default:
      return state;
  }
};

const rootReducer = combineReducers({
  priblo: pribloReducer,
  posts: PostsReducer,
  apiGenericErrorString: apiGenericErrorStringReducer,
});

export default rootReducer;
