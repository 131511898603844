import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import {
  Badge,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
} from 'reactstrap';

import AppLayout from './layouts/AppLayout';
import Uploader from '../components/Uploader';

import privacyToColor from '../modules/Helpers';
import { pribloShape } from '../shapes';

import './CreatePost.css';

class CreatePostView extends Component {
  render() {
    const { priblo } = this.props;

    return (
      <Row className="createPost">
        <Col xs={12}>
          <h2>New Post</h2>
          <h3>
            { priblo.name }
            {' '}
            <Badge color={privacyToColor(priblo.privacy)}>{priblo.privacy}</Badge>
          </h3>
          <Form>
            <FormGroup>
              <Input
                type="textarea"
                name="email"
                placeholder="..."
                required
              />
            </FormGroup>
            <FormGroup>
              <Uploader />
            </FormGroup>
          </Form>
          <ul className="timeline">
            <li className="cell">
              <h3>Create post</h3>
            </li>
          </ul>
        </Col>
      </Row>
    );
  }
}

CreatePostView.propTypes = {
  priblo: pribloShape.isRequired,
};

export default AppLayout(connect(null, null)(CreatePostView));
